export const ADD_RECOMMENDED_TOPICS_SUCCESS = "ADD_RECOMMENDED_TOPICS_SUCCESS";
export const UPDATE_RECOMMENDED_TOPICS_SUCCESS =
  "UPDATE_RECOMMENDED_TOPICS_SUCCESS";
export const DELETE_RECOMMENDED_TOPICS_SUCCESS =
  "DELETE_RECOMMENDED_TOPICS_SUCCESS";
export const FETCH_RECOMMENDED_TOPICS_SUCCESS =
  "FETCH_RECOMMENDED_TOPICS_SUCCESS";
export const FETCH_RECOMMENDED_TOPICS_FAILURE =
  "FETCH_RECOMMENDED_TOPICS_FAILURE";
export const FETCH_RECOMMENDED_TOPICS_IN_PROGRESS =
  "FETCH_RECOMMENDED_TOPICS_IN_PROGRESS";

export interface IReturnType {
  type: string;
  payload: any;
}
