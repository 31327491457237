import * as types from "../types/faqsTypes";

export interface I_Faqs {
  id: number;
  question: string;
  answer: string;
}

export interface IFAQsInitialState {
  errMsg: string;
  FAQs: Array<I_Faqs>;
  loading: boolean;
  totalRecords: number;
  rowsCount: number;
  page: number;
}

const initialState = {
  errMsg: "",
  FAQs: [],
  loading: false,
  totalRecords: 0,
  rowsCount: 10,
  page: 1,
};

export const faqsReducer = (
  state: IFAQsInitialState = initialState,
  action: types.IReturnType
) => {
  switch (action.type) {
    case types.ADD_FAQS_SUCCESS:
      return {
        ...state,
        FAQs: [...state.FAQs, action.payload],
      };
    case types.UPDATE_FAQS_SUCCESS:
      return {
        ...state,
        FAQs: state.FAQs.map((faqs) => {
          return faqs.id === action.payload.id ? action.payload : faqs;
        }),
      };
    case types.FETCH_FAQS_SUCCESS:
      return {
        ...state,
        FAQs: action.payload.FAQs,
        totalRecords: action.payload.totalRecords,
        page: action.payload.page,
      };
    case types.FETCH_FAQS_FAILURE:
      return { ...state, errMsg: action.payload };
    case types.FETCH_FAQS_IN_PROGRESS:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
