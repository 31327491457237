import * as types from "../types/interestTypes";

export interface I_INTEREST {
  id: number;
  name: string;
  color_code: string;
}

export interface IInterestInitialState {
  errMsg: string;
  interest: Array<I_INTEREST>;
  loading: boolean;
  totalRecords: number;
  rowsCount: number;
  page: number;
}

const initialState = {
  errMsg: "",
  interest: [],
  loading: false,
  totalRecords: 0,
  rowsCount: 10,
  page: 1,
};

export const interestReducer = (
  state: IInterestInitialState = initialState,
  action: types.IReturnType
) => {
  switch (action.type) {
    case types.ADD_INTEREST_SUCCESS:
      return { ...state, interest: [...state.interest, action.payload] };
    case types.UPDATE_INTEREST_SUCCESS:
      return {
        ...state,
        interest: state.interest.map((interest) => {
          if (interest.id === action.payload.id) return action.payload;
          else return interest;
        }),
      };
    case types.FETCH_INTEREST_SUCCESS:
      return {
        ...state,
        interest: action.payload.interest,
      };
    case types.FETCH_INTEREST_FAILURE:
      return { ...state, errMsg: action.payload };
    case types.FETCH_INTEREST_IN_PROGRESS:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
