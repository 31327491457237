export const UPDATE_REPORTED_USER_SUCCESS = "UPDATE_REPORTED_USER_SUCCESS";
export const FETCH_REPORTED_USER_SUCCESS = "FETCH_REPORTED_USER_SUCCESS";
export const FETCH_SEARCH_REPORTED_USER_SUCCESS =
  "FETCH_SEARCH_REPORTED_USER_SUCCESS";
export const FETCH_REPORTED_USER_FAILURE = "FETCH_REPORTED_USER_FAILURE";
export const FETCH_REPORTED_USER_IN_PROGRESS =
  "FETCH_REPORTED_USER_IN_PROGRESS";
export const FETCH_REPORTED_USER_ORDERS_SUCCESS =
  "FETCH_REPORTED_USER_ORDERS_SUCCESS";

export interface IReturnType {
  type: string;
  payload: any;
}
