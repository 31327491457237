import * as firebase from "firebase";
import config from "../config";

const firebaseApp = firebase.initializeApp(config.firebaseConfig);
export const fireAuth = firebaseApp.auth();

export function getFirebaseInstance() {
  return firebaseApp.app();
}

export function getFirebaseUser() {
  return new Promise((resolve, reject) => {
    fireAuth.onAuthStateChanged((user) => {
      if (user) {
        resolve(user);
      } else {
        reject();
      }
    });
  });
}

export const initializeFirebase = (credentials) => {
  if (!firebaseApp.apps.length) {
    firebaseApp.initializeApp(credentials);
  }
};

export const signOut = () => {
  fireAuth
    .signOut()
    .then(() => {
      localStorage.removeItem("idToken");
      localStorage.removeItem("permissions");
      localStorage.removeItem("email");
      localStorage.removeItem("userDetail");
    })
    .catch((error) => {
      console.error(error);
    });
};

export const db = firebase.firestore();
