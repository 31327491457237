import { INeighbourhood_InitialState, myNeighbourhoodReducer } from './reducers/myNeighbourhoodReducer';
import {
  feedCampaignsReducer,
  IFeedCampaignsInitialState,
} from "./reducers/campaignsReducer";
import {
  transactionPostsReducer,
  ITransactionPostsInitialState,
} from "./reducers/postsReducer";
import {
  transactionRequestReducer,
  ITransactionRequestsInitialState,
} from "./reducers/requestReducer";
import { applyMiddleware, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";

import {
  ISnackbarInitialState,
  snackbarReducer,
} from "./reducers/snackbarReducer";
import themeReducer from "../redux/reducers/themeReducers";
import {
  IOnboardingInitialState,
  onboardingReducer,
} from "./reducers/onboardingReducer";
import {
  consumeProvideReducer,
  ICategoryInitialState,
} from "./reducers/consumeProvideReducer";
import {
  INotificationInitialState,
  notificationReducer,
} from "./reducers/notificationReducer";
import {
  IInterestInitialState,
  interestReducer,
} from "./reducers/interestReducer";

import { IFEED_CONFIG, feedReducer } from "./reducers/feedReducer";
import {
  IFeedReportInitialState,
  feedReportReducer,
} from "./reducers/feedReportReducer";
import {
  advertisementReducer,
  I_ADVERTISEMENT_InitialState,
} from "./reducers/advertisementReducer";
import {
  ILoyaltyConfigInitialState,
  loyaltyConfigReducer,
} from "./reducers/loyaltyReducer";
import { IUsersInitialState, userReducer } from "./reducers/userReducer";
import {
  ITransactionOrderInitialState,
  transactionOrderReducer,
} from "./reducers/transactionOrdersReducer";
import {
  IWalletInitialState,
  userWalletReducer,
} from "./reducers/userWalletReducer";
import {
  postCodeReducer,
  IPOSTCODEInitialState,
} from "./reducers/postCodeReducer";
import { IMyUserInitialState, myUserReducer } from "./reducers/myUserReducer";
import {
  IRecommendedTopicsInitialState,
  recommendedTopicsReducer,
} from "./reducers/recommendedTopicsReducer";
import {
  IPoliciesInitialState,
  policiesReducer,
} from "./reducers/policiesReducer";
import { IFAQsInitialState, faqsReducer } from "./reducers/faqsReducer";
import {
  IFeedback_InitialState,
  feedbackReducer,
} from "./reducers/feedbackReducer";

import {
  ISendNotificationInitialState,
  sendNotificationReducer,
} from "./reducers/sendNotificationReducer";
import {
  endUserReducer,
  IEndUsersInitialState,
} from "./reducers/endUserReducer";

import {
  IPaymentConfigInitialState,
  paymentConfigReducer,
} from "./reducers/paymentConfigReducer";
import {
  IKudosRulesInitialState,
  kudosRulesReducer,
} from "./reducers/kudosRulesReducer";
import {
  ITransactionServicesInitialState,
  transactionServicesReducer,
} from "./reducers/servicesReducer";
import {
  IReportedUsersInitialState,
  reportedUserReducer,
} from "./reducers/reportedUserReducer";
import { feedbackFormReducer, IFeedback_Form_InitialState } from "./reducers/feedbackFormReducer";
import { I_Keyword_Masking_InitialState, keywordMaskingReducer } from './reducers/keywordMaskingReducer';

export interface IRootReducer {
  advertisementStore: I_ADVERTISEMENT_InitialState;
  consumeProvideTypeStore: ICategoryInitialState;
  interestTypeStore: IInterestInitialState;
  feedStore: IFEED_CONFIG;
  feedReportStore: IFeedReportInitialState;
  loyaltyStore: ILoyaltyConfigInitialState;
  notificationTypeStore: INotificationInitialState;
  onboardingStore: IOnboardingInitialState;
  snackbarStore: ISnackbarInitialState;
  transactionOrdersStore: ITransactionOrderInitialState;
  userStore: IUsersInitialState;
  postCodesStore: IPOSTCODEInitialState;
  myUserStore: IMyUserInitialState;
  recommendedTopicsStore: IRecommendedTopicsInitialState;
  policiesStore: IPoliciesInitialState;
  faqsStore: IFAQsInitialState;
  feedbackStore: IFeedback_InitialState;
  sendNotificationStore: ISendNotificationInitialState;
  endUserStore: IEndUsersInitialState;
  paymentConfigStore: IPaymentConfigInitialState;
  kudosRuleStore: IKudosRulesInitialState;
  servicesStore: ITransactionServicesInitialState;
  requestsStore: ITransactionRequestsInitialState;
  reportedUserStore: IReportedUsersInitialState;
  postsStore: ITransactionPostsInitialState;
  campaignsStore: IFeedCampaignsInitialState;
  walletStore: IWalletInitialState;
  feedbackForm: IFeedback_Form_InitialState;
  myNeighbourhood: INeighbourhood_InitialState;
  keywordMasking: I_Keyword_Masking_InitialState;
}

const rootReducer = combineReducers({
  advertisementTypeStore: advertisementReducer,
  consumeProvideTypeStore: consumeProvideReducer,
  interestTypeStore: interestReducer,
  feedStore: feedReducer,
  feedReportStore: feedReportReducer,
  loyaltyStore: loyaltyConfigReducer,
  notificationTypeStore: notificationReducer,
  onboardingStore: onboardingReducer,
  snackbarStore: snackbarReducer,
  themeReducer: themeReducer,
  transactionOrdersStore: transactionOrderReducer,
  walletStore: userWalletReducer,
  userStore: userReducer,
  postCodesStore: postCodeReducer,
  myUserStore: myUserReducer,
  recommendedTopicsStore: recommendedTopicsReducer,
  policiesStore: policiesReducer,
  faqsStore: faqsReducer,
  feedbackStore: feedbackReducer,
  sendNotificationStore: sendNotificationReducer,
  endUserStore: endUserReducer,
  paymentConfigStore: paymentConfigReducer,
  kudosRuleStore: kudosRulesReducer,
  servicesStore: transactionServicesReducer,
  requestsStore: transactionRequestReducer,
  reportedUserStore: reportedUserReducer,
  postsStore: transactionPostsReducer,
  campaignsStore: feedCampaignsReducer,
  feedbackForm: feedbackFormReducer,
  myNeighbourhood: myNeighbourhoodReducer,
  keywordMasking: keywordMaskingReducer,
});

export const store = createStore(rootReducer, applyMiddleware(thunk));
