export const ADD_POST_CODE = "ADD_POST_CODE";
export const UPDATE_POST_CODE = "UPDATE_POST_CODE";
export const DELETE_POST_CODE = "DELETE_POST_CODE";
export const FETCH_POST_CODE_SUCCESS = "FETCH_POST_CODE_SUCCESS";
export const FETCH_POST_CODE_FAILURE = "FETCH_POST_CODE_FAILURE";
export const FETCH_POST_CODE_IN_PROGRESS = "FETCH_POST_CODE_IN_PROGRESS";

export interface IReturnType {
  type: string;
  payload: any;
}
