export const ADD_CONFIG_SUCCESS = "ADD_CONFIG_SUCCESS";
export const UPDATE_CONFIG_SUCCESS = "UPDATE_CONFIG_SUCCESS";
export const DELETE_CONFIG_SUCCESS = "DELETE_CONFIG_SUCCESS";
export const FETCH_CONFIG_SUCCESS = "FETCH_CONFIG_SUCCESS";
export const FETCH_CONFIG_FAILURE = "FETCH_CONFIG_FAILURE";
export const FETCH_FEED_IN_PROGRESS = "FETCH_FEED_IN_PROGRESS";

export interface IReturnType {
  type: string;
  payload: any;
}
