import * as types from "../types/paymentConfigTypes";

export interface IPaymentConfig {
  id: number;
  label: string;
  value: string;
}

export interface IPaymentConfigInitialState {
  errMsg: string;
  paymentConfigs: Array<IPaymentConfig>;
  loading: boolean;
  totalRecords: number;
  rowsCount: number;
  page: number;
  feeds: Object;
}

const initialState = {
  errMsg: "",
  paymentConfigs: [],
  feeds: {},
  loading: false,
  totalRecords: 0,
  rowsCount: 10,
  page: 1,
};

export const paymentConfigReducer = (
  state: IPaymentConfigInitialState = initialState,
  action: types.IReturnType
) => {
  switch (action.type) {
    case types.ADD_PAYMENT_CONFIG_SUCCESS:
      return { ...state, config: [...state.paymentConfigs, action.payload] };
    case types.UPDATE_PAYMENT_CONFIG_SUCCESS:
      return {
        ...state,
        paymentConfigs: state.paymentConfigs.map((config) => {
          if (config.id === action.payload.id) return action.payload;
          else return config;
        }),
      };
    case types.FETCH_PAYMENT_CONFIG_SUCCESS:
      return {
        ...state,
        paymentConfigs: action.payload.paymentConfigs,
      };
    case types.FETCH_PAYMENT_CONFIG_FAILURE:
      return { ...state, errMsg: action.payload };
    case types.FETCH_PAYMENT_CONFIG_IN_PROGRESS:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
