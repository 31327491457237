import * as types from "../types/reportedUserTypes";

export interface IReportedUsers {
  id: number;
  endUserName: string;
  email: string;
  phone_no: string;
  user_id: string;
}
export interface IEndUserBalanceAndOrders {
  id: number;
  wallet_balance: number;
  open_orders: number;
}

export interface IReportedUsersInitialState {
  errMsg: string;
  reportedUsers: Array<IReportedUsers>;
  endUserBalanceAndOrders: IEndUserBalanceAndOrders;
  loading: boolean;
  totalRecords: number;
  rowsCount: number;
  page: number;
  offset: number;
}

const initialState = {
  errMsg: "",
  reportedUsers: [],
  endUserBalanceAndOrders: {
    id: 0,
    wallet_balance: 0,
    open_orders: 0,
  },
  loading: false,
  totalRecords: 0,
  rowsCount: 10,
  page: 1,
  offset: 0,
};

export const reportedUserReducer = (
  state: IReportedUsersInitialState = initialState,
  action: types.IReturnType
) => {
  switch (action.type) {
    case types.UPDATE_REPORTED_USER_SUCCESS:
      return {
        ...state,
        reportedUsers: state.reportedUsers.map((endUser) => {
          if (endUser.user_id === action.payload.user_id) return action.payload;
          else return endUser;
        }),
      };
    case types.FETCH_REPORTED_USER_SUCCESS:
      return {
        ...state,
        reportedUsers: action.payload.reportedUsers,
        totalRecords: action.payload.totalRecords,
        page: action.payload.page,
      };
    case types.FETCH_SEARCH_REPORTED_USER_SUCCESS:
      return {
        ...state,
        reportedUsers: action.payload.reportedUsers,
        offset: action.payload.offset,
      };
    case types.FETCH_REPORTED_USER_ORDERS_SUCCESS:
      const { endUserBalanceAndOrders } = action.payload;
      return {
        ...state,
        endUserBalanceAndOrders: { ...endUserBalanceAndOrders },
      };
    case types.FETCH_REPORTED_USER_FAILURE:
      return { ...state, errMsg: action.payload };
    case types.FETCH_REPORTED_USER_IN_PROGRESS:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
