/*
 --- DEV Config
*/

// class DEV_CONFIG {
//   static firebaseConfig = {
//     apiKey: 'AIzaSyAbmk3VDVRssRsQCfJa2KtWQIvwv3cz6mI',
//     authDomain: 'hikinjo-development.firebaseapp.com',
//     projectId: 'hikinjo-development',
//     storageBucket: 'hikinjo-development.appspot.com',
//     messagingSenderId: '1053632061244',
//     appId: '1:1053632061244:web:171f202af69900e72c8839',
//     measurementId: 'G-RHQNP7KVDB',
//   };

//   static baseURL = 'https://qlh16wmty0.execute-api.eu-west-2.amazonaws.com';
//   static apiURL =
//     'https://3gnzcovpfnhu5giqcil55n4mje.appsync-api.eu-west-2.amazonaws.com/graphql';
//   static s3Config = {
//     accessKeyId: 'AKIAZGDRRZMUOCCGWLV5',
//     apiVersion: '2012-10-17',
//     bucketName: 'hikinjo-assets-dev',
//     dirName: 'admin-assets',
//     region: 'eu-west-2',
//     secretAccessKey: 'nLmb9Ta6/3Z+7W1K9udbP35txbFurHNMCvYMiItA',
//   };
// }

class PROD_CONFIG {
  static firebaseConfig = {
    apiKey: 'AIzaSyCyinKFJdB_xsuf2bhHwBc_LqKD3CNnC8k',
    authDomain: 'hikinjo-prod.firebaseapp.com',
    projectId: 'hikinjo-prod',
    storageBucket: 'hikinjo-prod.appspot.com',
    messagingSenderId: '346407094745',
    appId: '1:346407094745:web:b8b90ea4d7cfbe3c0931c8',
    measurementId: 'G-L6EHQ2J0M8',
  };

  static baseURL = 'https://api-admin.hikinjo.com';
  static apiURL = 'https://graphql.hikinjo.com/graphql';
  static s3Config = {
    accessKeyId: 'AKIAVAH4UM4ZIAL5XZJ6',
    apiVersion: '2012-10-17',
    bucketName: 'hikinjo-assets-prod',
    dirName: 'admin-assets',
    region: 'eu-west-2',
    secretAccessKey: 'Yw9lMSl303gi/xyweE/WPN/lPiutT6PpH4zBJg/R',
  };
}

export default PROD_CONFIG;
