import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  RouteComponentProps,
} from "react-router-dom";
import { dashboardLayoutRoutes, authLayoutRoutes } from "./index";
import withAuthProtection from "../hocs/withAuth";
import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";
import { RouteInfoType } from "../types/types";
import ProtectedRoute from "./ProtectedRoute";

const childRoutes = (
  Layout: React.ElementType,
  routes: Array<RouteInfoType>
  // envProps: any
) =>
  routes.map(
    (
      {
        component: Component,
        children,
        path,
        auth,
        id,
        name,
        routePermissions,
      },
      index: number
    ) => {
      const ProtectedProfile = withAuthProtection(path)(Component);
      return children ? (
        children.map((element, index: number) => {
          return (
            <Route
              key={index}
              path={element.path}
              exact
              render={(props: RouteComponentProps) => (
                <ProtectedRoute routePermissions={element.routePermissions}>
                  <Layout headerTitle={element.name}>
                    <element.component {...props} />
                  </Layout>
                </ProtectedRoute>
              )}
            />
          );
        })
      ) : Component ? (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <ProtectedRoute routePermissions={routePermissions}>
              <Layout headerTitle={name}>
                {auth ? (
                  <ProtectedProfile {...props} />
                ) : (
                  <Component {...props} />
                )}
              </Layout>
            </ProtectedRoute>
          )}
        />
      ) : null;
    }
  );

const Routes = (props: any) => {
  return (
    <Router>
      <Switch>
        {childRoutes(DashboardLayout, dashboardLayoutRoutes)}
        {childRoutes(AuthLayout, authLayoutRoutes)}
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
