export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const FETCH_NOTIFICATION_SUCCESS = "FETCH_NOTIFICATION_SUCCESS";
export const FETCH_NOTIFICATION_FAILURE = "FETCH_NOTIFICATION_FAILURE";
export const FETCH_NOTIFICATION_IN_PROGRESS = "FETCH_NOTIFICATION_IN_PROGRESS";
export const FETCH_NOTIFICATION_ACTIONS_SUCCESS =
  "FETCH_NOTIFICATION_ACTIONS_SUCCESS";

export interface IReturnType {
  type: string;
  payload: any;
}
