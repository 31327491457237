import * as types from "../types/campaignsTypes";

export interface I_FEED_CAMPAIGNS {
  id: number;
  name: string;
  description: string;
  to_date: string;
  from_date: string;
  code: string;
}

export interface IFeedCampaignsInitialState {
  errMsg: string;
  campaigns: Array<I_FEED_CAMPAIGNS>;
  loading: boolean;
  totalRecords: number;
  offset: number;
  rowsCount: number;
  page: number;
}

const initialState = {
  errMsg: "",
  campaigns: [],
  loading: false,
  totalRecords: 0,
  offset: 0,
  rowsCount: 10,
  page: 1,
};

export const feedCampaignsReducer = (
  state: IFeedCampaignsInitialState = initialState,
  action: types.IReturnType
) => {
  switch (action.type) {
    case types.ADD_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: [action.payload, ...state.campaigns],
      };
    case types.UPDATE_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: state.campaigns.map((campaigns) => {
          if (campaigns.id === action.payload.id) return action.payload;
          else return campaigns;
        }),
      };

    case types.FETCH_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: action.payload.campaigns,
        offset: action.payload.offset,
        totalRecords: action.payload.totalRecords,
      };
    case types.FETCH_CAMPAIGNS_FAILURE:
      return { ...state, errMsg: action.payload };
    case types.FETCH_CAMPAIGNS_IN_PROGRESS:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
