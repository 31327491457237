import * as types from "../types/servicesTypes";

export interface I_TRANSACTION_SERVICE {
  consumer_first_name: string;
  consumer_last_name: string;
  created_at: string;
  id: number;
  order_code: string;
  order_id: number;
  orderStatus: string;
  provider_first_name: string;
  provider_last_name: string;
  scheduleDate: string;
  scheduleTime: string;
  status_tag: string;
  updated_at: string;
}
export interface I_APP_USERS {
  id: number;
  user_id: string;
  email: string;
  phone_no: string;
}

export interface ITransactionServicesInitialState {
  errMsg: string;
  services: Array<I_TRANSACTION_SERVICE>;
  appUsers: Array<I_APP_USERS>;
  loading: boolean;
  totalRecords: number;
  offset: number;
  rowsCount: number;
  page: number;
}

const initialState = {
  errMsg: "",
  services: [],
  appUsers: [],
  loading: false,
  totalRecords: 0,
  offset: 0,
  rowsCount: 10,
  page: 1,
};

export const transactionServicesReducer = (
  state: ITransactionServicesInitialState = initialState,
  action: types.IReturnType
) => {
  switch (action.type) {
    case types.ADD_SERVICE_SUCCESS:
      return {
        ...state,
        services: [...state.services, action.payload],
      };
    case types.FETCH_SERVICE_SUCCESS:
      return {
        ...state,
        services: action.payload.services,
        offset: action.payload.offset,
        totalRecords: action.payload.totalRecords,
        page: action.payload.page,
      };
    case types.FETCH_APP_USERS_SUCCESS:
      return {
        ...state,
        appUsers: action.payload.appUser,
      };
    case types.FETCH_SERVICE_FAILURE:
      return { ...state, errMsg: action.payload };
    case types.FETCH_SERVICE_IN_PROGRESS:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
