import * as React from "react";
import styled, { withTheme } from "styled-components";

import { useHistory } from "react-router-dom";
import {
  AppBar as MuiAppBar,
  Grid,
  Hidden,
  IconButton as MuiIconButton,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import { Power } from "react-feather";
import { signOut } from "../configs/firebaseConfig";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  box-shadow: ${(props) => props.theme.shadows[1]};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    marginrightwidth: 22px;
    height: 22px;
  }
`;

const useStyles = makeStyles((theme) => ({
  headerText: {
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(7),
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      marginLeft: theme.spacing(1),
    },
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  toolBar: {
    marginLeft: "10px",
  },
  logoutButton: {
    marginRight: "10px",
  },
}));

function UserMenu() {
  const history = useHistory();
  return (
    <React.Fragment>
      <IconButton
        aria-owns="menu-appbar"
        aria-haspopup="true"
        onClick={() => {
          signOut();
          history.push("/sign-in");
        }}
        color="inherit"
      >
        <Power />
      </IconButton>
    </React.Fragment>
  );
}

type HeaderProps = {
  theme: {};
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
  // changeENV;
  // env: string;
  headerTitle: string;
};

const Header: React.FC<HeaderProps> = ({
  onDrawerToggle,
  // env,
  // changeENV,
  headerTitle,
}) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar className={classes.toolBar}>
          <Grid container alignItems="center" spacing={10}>
            <div className={classes.wrapper}>
              <Hidden mdUp>
                <Grid item>
                  <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={onDrawerToggle}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
              </Hidden>
              <Grid item classes={{ root: classes.headerText }}>
                <Typography variant="h3" gutterBottom display="inline">
                  {headerTitle}
                </Typography>
              </Grid>
              <Grid item className={classes.logoutButton}>
                <UserMenu />
              </Grid>
            </div>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Header);
