import * as types from "../types/loyaltyTypes";

export interface ILoyaltyKudos {
  id: number;
  image_url: string;
  title: string;
}
export interface ILoyaltyConfig {
  id: number;
  label: string;
  value: string;
}

export interface ILoyaltyConfigInitialState {
  errMsg: string;
  loyaltyKudos: Array<ILoyaltyKudos>;
  loyaltyConfig: Array<ILoyaltyConfig>;
  loading: boolean;
  totalRecords: number;
  rowsCount: number;
  page: number;
  loyalty: Object;
}

const initialState = {
  loyalty: {},
  errMsg: "",
  loyaltyKudos: [],
  loyaltyConfig: [],
  loading: false,
  totalRecords: 0,
  rowsCount: 10,
  page: 1,
};

export const loyaltyConfigReducer = (
  state: ILoyaltyConfigInitialState = initialState,
  action: types.IReturnType
) => {
  switch (action.type) {
    case types.ADD_LOYALTY_CONFIG:
      return {
        ...state,
        loyaltyKudos: [...state.loyaltyKudos, action.payload],
      };
    case types.UPDATE_LOYALTY_CONFIG:
      return {
        ...state,
        loyaltyKudos: state.loyaltyKudos.map((loyaltyConfig) => {
          if (loyaltyConfig.id === action.payload.id) return action.payload;
          else return loyaltyConfig;
        }),
      };
    case types.FETCH_LOYALTY_CONFIG_SUCCESS:
      return {
        ...state,
        loyalty: action.payload.loyalty,
        loyaltyKudos: action.payload.loyaltyKudos,
        loyaltyConfig: action.payload.loyaltyConfig,
      };
    case types.FETCH_LOYALTY_CONFIG_FAILURE:
      return { ...state, errMsg: action.payload };
    case types.FETCH_LOYALTY_CONFIG_IN_PROGRESS:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
