import * as types from "../types/transactionOrdersTypes";

export interface I_TRANSACTION_ORDER {
  consumer_first_name: string;
  consumer_last_name: string;
  created_at: string;
  id: number;
  order_code: string;
  order_id: number;
  orderStatus: string;
  provider_first_name: string;
  provider_last_name: string;
  scheduleDate: string;
  scheduleTime: string;
  status_tag: string;
  updated_at: string;
}

export interface ITransactionOrderInitialState {
  errMsg: string;
  orders: Array<I_TRANSACTION_ORDER>;
  loading: boolean;
  totalRecords: number;
  offset: number;
  rowsCount: number;
  page: number;
}

const initialState = {
  errMsg: "",
  orders: [],
  loading: false,
  totalRecords: 0,
  offset: 0,
  rowsCount: 10,
  page: 1,
};

export const transactionOrderReducer = (
  state: ITransactionOrderInitialState = initialState,
  action: types.IReturnType
) => {
  switch (action.type) {
    case types.UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        orders: state.orders.map((order) => {
          return order.order_id === action.payload.order_id
            ? action.payload
            : order;
        }),
      };
    case types.FETCH_ORDER_SUCCESS:
      return {
        ...state,
        orders: action.payload.orders,
        offset: action.payload.offset,
      };
    case types.FETCH_ORDER_FAILURE:
      return { ...state, errMsg: action.payload };
    case types.FETCH_ORDER_IN_PROGRESS:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
