import React from "react";

import async from "../components/Async";
import {
  AccountBoxRounded,
  AirplayRounded,
  AlarmOnRounded,
  ArtTrackRounded,
  AssessmentRounded,
  BorderColorOutlined,
  EventNote,
  HelpOutlineRounded,
  Home,
  LoyaltyRounded,
  NotificationImportantRounded,
  PaymentRounded,
  Person,
  ReceiptRounded,
  Group
} from "@material-ui/icons";

// Pages components
const OnBoarding = async(() => import("../pages/onBoarding"));
const OnboardingConfig = async(() => import("../pages/onBoarding/config"));
const Documentation = async(() => import("../pages/onBoarding/documentation"));
const ConsumeProvide = async(() => import("../pages/consumeProvide"));
const Config = async(() => import("../pages/consumeProvide/config"));
const Interest = async(() => import("../pages/onBoarding/interest"));
const Category = async(() => import("../pages/consumeProvide/category"));
const Transaction = async(() => import("../pages/transaction"));
const Feed = async(() => import("../pages/feed"));
const Advertisement = async(() => import("../pages/feed/advertisement"));
const ReportedPost = async(() => import("../pages/feed/reportedPost"));
const FeedConfig = async(() => import("../pages/feed/config"));
const Loyalty = async(() => import("../pages/loyalty"));
const Insta = async(() => import("../pages/insta"));
const Analytics = async(() => import("../pages/analytics"));
const Notification = async(() => import("../pages/notifications"));
const NotificationsTemplates = async(() =>
  import("../pages/notifications/template")
);
const SendNotification = async(() =>
  import("../pages/notifications/sendNotification")
);
const SendNotificationDetailForm = async(() =>
  import("../pages/notifications/sendNotification/sendNotificationDetailForm")
);
const TemplateNotificationDetailForm = async(() =>
  import("../pages/notifications/template/templateNotificationDetailForm")
);

const HomePage = async(() => import("../pages/home"));
const HelpAndSupport = async(() => import("../pages/helpAndSupport"));
const FAQs = async(() => import("../pages/helpAndSupport/FAQs"));
const RecommendedTopics = async(() =>
  import("../pages/helpAndSupport/recommendedTopics")
);
const RecommendedTopicsAddEditForm = async(() =>
  import("../pages/helpAndSupport/recommendedTopics/recommendedTopicsForm")
);

const Account = async(() => import("../pages/account"));
const EndUsers = async(() => import("../pages/account/endUsers"));
const ReportedUsers = async(() => import("../pages/account/reportedUsers"));

const Policies = async(() => import("../pages/helpAndSupport/policies"));
const PoliciesAddEditForm = async(() =>
  import("../pages/helpAndSupport/policies/policiesForm")
);
const ReviewsAndFeedbacks = async(() =>
  import("../pages/helpAndSupport/reviewsAndFeedbacks")
);

const FeedbackForm = async(() =>
  import("../pages/helpAndSupport/feedbackForm")
);


const UsersAddEditForm = async(() => import("../pages/users/UsersAddEditForm"));
const ReportForm = async(() => import("../pages/feed/reportedPost/reportForm"));
const Masteruser = async(() => import("../pages/users"));
const Orders = async(() => import("../pages/transaction/orders"));
const Wallets = async(() => import("../pages/transaction/wallets"));
const Services = async(() => import("../pages//transaction/services"));
const Request = async(() => import("../pages/transaction/requests"));
const Posts = async(() => import("../pages/transaction/posts"));
const Campaigns = async(() => import("../pages/feed/campaigns"));

const LoyaltyConfig = async(() => import("../pages/loyalty/config"));
const LoyaltyRules = async(() => import("../pages/loyalty/rules"));
const PostCodes = async(() => import("../pages/postCodes"));
const Payment = async(() => import("../pages/payment"));
const PaymentConfig = async(() => import("../pages/payment/config"));
const MyNeighborhood = async(() => import("../pages/myNeighborhood"));
const KeyworkMasking = async(() => import("../pages/keywordMasking"))

// Auth Components
const AccessDenied = async(() => import("../pages/auth/AccessDenied"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const SignIn = async(() => import("../pages/auth/SignIn"));

const home = {
  id: "home",
  name: "Home",
  path: "/",
  icon: <Home />,
  component: HomePage,
  children: null,
  auth: true,
  routePermissions: "All",
};

const users = {
  id: "AdminCMSUsers",
  name: "Admin CMS Users",
  path: "/adminUsers",
  icon: <AccountBoxRounded />,
  component: Masteruser,
  children: null,
  auth: false,
  routePermissions: "All",
};

const onBoarding = {
  id: "Onboarding",
  name: "OnBoarding",
  path: "/onboarding",
  auth: true,
  icon: <AirplayRounded />,
  component: OnBoarding,
  children: [
    {
      id: "IntroScreenSlider",
      name: "Feature Callout Screen",
      path: "/onboarding/featureCalloutScreen",
      icon: <EventNote />,
      component: OnBoarding,
      routePermissions: "IntroScreenSlider",
    },
    {
      id: "OnboardingConfig",
      name: "Configuration",
      path: "/onboarding/Config",
      icon: <EventNote />,
      component: OnboardingConfig,
      routePermissions: "OnboardingConfig",
    },
    {
      id: "Interest",
      name: "Interest",
      path: "/onboarding/interest",
      icon: <EventNote />,
      component: Interest,
      routePermissions: "Interest",
    },
    {
      id: "AddressVerificationDocuments",
      name: "Address Verification Documents",
      path: "/onboarding/documentation",
      icon: <EventNote />,
      component: Documentation,
      routePermissions: "AddressVerificationDocuments",
    },
  ],
};

const consumeProvide = {
  id: "ConsumeProvide",
  name: "Consume Provide",
  path: "/consumeAndProvide",
  icon: <ArtTrackRounded />,
  component: ConsumeProvide,
  children: [
    {
      id: "Category",
      name: "Category",
      path: "/consumeAndProvide/category",
      icon: <EventNote />,
      component: Category,
      routePermissions: "Category",
    },
    {
      id: "ConsumeProvideConfig",
      name: "Configuration",
      path: "/consumeAndProvide/config",
      icon: <EventNote />,
      component: Config,
      routePermissions: "ConsumeProvideConfig",
    },
  ],
  auth: true,
};

const feed = {
  id: "Feed",
  name: "Feed",
  path: "/feed",
  icon: <BorderColorOutlined />,
  component: Feed,
  children: [
    {
      id: "ReportedPost",
      name: "Reported Posts/Comments",
      path: "/feed/reportedPost",
      icon: <EventNote />,
      component: ReportedPost,
      routePermissions: "ReportedPost",
    },
    {
      id: "FeedConfig",
      name: "Configuration",
      path: "/feedConfig",
      icon: <EventNote />,
      component: FeedConfig,
      routePermissions: "FeedConfig",
    },
    {
      id: "Advertisement",
      name: "Advertisements",
      path: "/feed/advertisement",
      icon: <EventNote />,
      component: Advertisement,
      routePermissions: "Advertisement",
    },
    {
      id: "Posts",
      name: "Posts",
      path: "/feed/posts",
      icon: <EventNote />,
      component: Posts,
      routePermissions: "Posts",
    },
    {
      id: "Campaigns",
      name: "Campaigns",
      path: "/feed/campaigns",
      icon: <EventNote />,
      component: Campaigns,
      routePermissions: "Campaigns",
    },
  ],
  auth: true,
};

const transaction = {
  id: "Transaction",
  name: "Transaction",
  path: "/transaction",
  icon: <ReceiptRounded />,
  component: Transaction,
  children: [
    {
      id: "Orders",
      name: "Orders",
      path: "/transaction/orders",
      icon: <EventNote />,
      component: Orders,
      routePermissions: "Orders",
    },
    {
      id: "Request",
      name: "Request",
      path: "/transaction/request",
      icon: <EventNote />,
      component: Request,
      routePermissions: "Request",
    },
    {
      id: "Services",
      name: "Services",
      path: "/transaction/services",
      icon: <EventNote />,
      component: Services,
      routePermissions: "Services",
    },
    {
      id: "Wallets",
      name: "Wallets",
      path: "/transaction/wallets",
      icon: <EventNote />,
      component: Wallets,
      routePermissions: "Orders",
    },
  ],
  auth: true,
};

const loyalty = {
  id: "Loyalty",
  name: "Loyalty",
  path: "/loyalty",
  icon: <LoyaltyRounded />,
  component: Loyalty,
  children: [
    {
      id: "EarnKudosSlide",
      name: "Earn Kudos Slide",
      path: "/loyalty/Kudos",
      icon: <EventNote />,
      component: Loyalty,
      routePermissions: "EarnKudosSlide",
    },
    {
      id: "LoyaltyConfig",
      name: "Configuration",
      path: "/loyalty/config",
      icon: <EventNote />,
      component: LoyaltyConfig,
      routePermissions: "All",
    },
    {
      id: "kudosRules",
      name: "Kudos Point rules",
      path: "/loyalty/rules",
      icon: <EventNote />,
      component: LoyaltyRules,
      routePermissions: "kudosRules",
    },
  ],
  auth: true,
};

const insta = {
  id: "Insta",
  name: "Insta",
  path: "/insta",
  icon: <AlarmOnRounded />,
  component: Insta,
  children: null,
  auth: true,
};

const analytics = {
  id: "Analytics",
  name: "Analytics",
  path: "/analytics",
  icon: <AssessmentRounded />,
  component: Analytics,
  children: null,
  auth: true,
};

const notifications = {
  id: "Notifications",
  name: "Notifications",
  path: "/notification",
  icon: <NotificationImportantRounded />,
  component: Notification,
  routePermissions: "Notifications",
  auth: true,
  children: [
    {
      id: "AdminNotification",
      name: "Admin Notification",
      path: "/notification/admin-notification",
      icon: <EventNote />,
      component: SendNotification,
      routePermissions: "AdminNotification",
    },
    {
      id: "NotificationTemplates",
      name: "Templates",
      path: "/notification/templates",
      icon: <EventNote />,
      component: NotificationsTemplates,
      routePermissions: "NotificationTemplates",
    },
  ],
};
const postCodes = {
  id: "ConfigurePostCodes",
  name: "Configure Post Codes",
  path: "/postcodes",
  icon: <EventNote />,
  component: PostCodes,
  routePermissions: "ConfigurePostCodes",
  children: null,
  auth: true,
};

const account = {
  id: "Account",
  name: "Account",
  path: "/account",
  icon: <AccountBoxRounded />,
  component: Account,
  children: [
    {
      id: "EndUsers",
      name: "End Users",
      path: "/account/users",
      icon: <EventNote />,
      component: EndUsers,
      routePermissions: "EndUsers",
    },
    {
      id: "ReportedUsers",
      name: "Reported Users",
      path: "/account/reportedUsers",
      icon: <EventNote />,
      component: ReportedUsers,
      routePermissions: "ReportedUsers",
    },
  ],
  auth: true,
};
const helpAndSupport = {
  id: "HelpAndSupport",
  name: "Help & Support",
  path: "/helpAndSupport",
  icon: <HelpOutlineRounded />,
  component: HelpAndSupport,
  auth: true,
  children: [
    {
      id: "FAQs",
      name: "FAQs",
      path: "/helpAndSupport/faqs",
      icon: <EventNote />,
      component: FAQs,
      routePermissions: "FAQs",
    },
    {
      id: "RecommendedTopics",
      name: "Recommended Topics",
      path: "/helpAndSupport/recommended-topics",
      icon: <EventNote />,
      component: RecommendedTopics,
      routePermissions: "RecommendedTopics",
    },
    {
      id: "Policies",
      name: "Policies",
      path: "/helpAndSupport/policies",
      icon: <EventNote />,
      component: Policies,
      routePermissions: "Policies",
    },
    {
      id: "ReviewsAndFeedback",
      name: "Reviews & Feedback",
      path: "/helpAndSupport/reviewsAndFeedback",
      icon: <EventNote />,
      component: ReviewsAndFeedbacks,
      routePermissions: "ReviewsAndFeedback",
    },
    {
      id: "FeedbackForm",
      name: "Feedback Form",
      path: "/helpAndSupport/feedbackForm",
      icon: <EventNote />,
      component: FeedbackForm,
      routePermissions: "All",
    }
  ],
};

const usersAddEditForm = {
  id: "User Form",
  name: "User Permissions Form",
  path: "/users/addAndEdit/form",
  icon: <EventNote />,
  component: UsersAddEditForm,
  children: null,
  auth: true,
  routePermissions: "All",
};
const policiesAddEditForm = {
  id: "Policies Form",
  name: "Policies Form",
  path: "/policies/addAndEdit/form",
  icon: <EventNote />,
  component: PoliciesAddEditForm,
  children: null,
  auth: true,
  routePermissions: "All",
};

const recommendedTopicsAddEditForm = {
  id: "Recommended Topics Form",
  name: "Recommended Topics Form",
  path: "/recommendedTopics/addAndEdit/form",
  icon: <EventNote />,
  component: RecommendedTopicsAddEditForm,
  children: null,
  auth: true,
  routePermissions: "All",
};

const reportAddEditForm = {
  id: "Reported Posts/Comments",
  name: "Reported Posts/Comments",
  path: "/reportedPostsComments/data",
  icon: <EventNote />,
  component: ReportForm,
  children: null,
  auth: true,
  routePermissions: "All",
};
const sendNotificationAddEditForm = {
  id: "sendNotificationData",
  name: "Send Notification Data",
  path: "/sendNotification/data",
  icon: <EventNote />,
  component: SendNotificationDetailForm,
  children: null,
  auth: true,
  routePermissions: "All",
};
const templateNotificationAddEditForm = {
  id: "templateNotificationData",
  name: "Template Notification Data",
  path: "/templateNotification/data",
  icon: <EventNote />,
  component: TemplateNotificationDetailForm,
  children: null,
  auth: true,
  routePermissions: "All",
};
const payment = {
  id: "Payment",
  name: "Payment",
  path: "/payment",
  icon: <PaymentRounded />,
  component: Payment,
  children: [
    {
      id: "PaymentConfig",
      name: "Configuration",
      path: "/payment/config",
      icon: <EventNote />,
      component: PaymentConfig,
      routePermissions: "PaymentConfig",
    },
  ],
  auth: true,
};
const myNeighborhood = {
  id: "myNeighborhood",
  name: "My Neighborhood",
  path: "/myNeighborhood",
  icon: <Group/>,
  component: MyNeighborhood,
  auth: true,
  children: null,
  routePermissions: "All"
};
const keywordMasking = {
  id: "keywordMasking",
  name: "Keyword Masking",
  path: "/keywordMasking",
  icon: <Group/>,
  component: KeyworkMasking,
  auth: true,
  children: null,
  routePermissions: "All"
}

const authRoutes = {
  id: "Auth",
  path: "/",
  icon: <Person />,
  auth: true,
  children: [
    {
      id: "signIn",
      path: "/sign-in",
      name: "Sign In",
      component: SignIn,
      routePermissions: "All",
    },
    {
      id: "404",
      path: "/404",
      name: "404 Page",
      component: Page404,
      routePermissions: "All",
    },
    {
      id: "500",
      path: "/500",
      name: "500 Page",
      component: Page500,
      routePermissions: "All",
    },
    {
      id: "403",
      path: "/403",
      name: "403 Page",
      component: AccessDenied,
      routePermissions: "All",
    },
    {
      id: "resetPswd",
      path: "/reset-password",
      name: "Reset Password",
      component: ResetPassword,
      routePermissions: "All",
    },
  ],
  component: null,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  account,
  analytics,
  consumeProvide,
  feed,
  helpAndSupport,
  home,
  insta,
  loyalty,
  notifications,
  onBoarding,
  payment,
  policiesAddEditForm,
  postCodes,
  recommendedTopicsAddEditForm,
  reportAddEditForm,
  sendNotificationAddEditForm,
  templateNotificationAddEditForm,
  transaction,
  users,
  usersAddEditForm,
  myNeighborhood,
  keywordMasking
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  home,
  users,
  onBoarding,
  consumeProvide,
  feed,
  transaction,
  loyalty,
  notifications,
  postCodes,
  helpAndSupport,
  account,
  payment,
  myNeighborhood,
  keywordMasking
];
