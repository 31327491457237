import * as types from "../types/userTypes";

export interface IUsers {
  id: number;
  fullName: string;
  email: string;
  permissions: any;
  password: string;
  user_id: string;
}

export interface IUsersInitialState {
  errMsg: string;
  users: Array<IUsers>;
  loading: boolean;
  totalRecords: number;
  rowsCount: number;
  page: number;
}

const initialState = {
  errMsg: "",
  users: [],
  loading: false,
  totalRecords: 0,
  rowsCount: 10,
  page: 1,
};

export const userReducer = (
  state: IUsersInitialState = initialState,
  action: types.IReturnType
) => {
  switch (action.type) {
    case types.ADD_USER_SUCCESS:
      return { ...state, users: [...state.users, action.payload] };
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map((user) => {
          if (user.user_id === action.payload.user_id) return action.payload;
          else return user;
        }),
      };
    case types.FETCH_USER_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
        totalRecords: action.payload.totalRecords,
        page: action.payload.page,
      };
    case types.FETCH_SEARCH_USER_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
        totalRecords: action.payload.totalRecords,
        page: action.payload.page,
      };
    case types.FETCH_USER_FAILURE:
      return { ...state, errMsg: action.payload };
    case types.FETCH_USER_IN_PROGRESS:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
