const ENV_VARS = ["DEV", "QA", "TEST", "PROD", "UAT"];

export enum ENV_TYPE {
  QA = "QA",
  DEV = "DEV",
  PROD = "PROD",
}

export const getEnv = () => {
  let environment = process.env.REACT_APP_ENV || ENV_TYPE.DEV;
  localStorage.setItem("ENV", environment);
  return environment;
};

export default ENV_VARS;
