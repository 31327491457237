import React from "react";

import { getPermissions } from "../configs/axiosInterceptor";
import AccessDenied from "../pages/auth/AccessDenied";

interface ProtectedRouteProps {
  routePermissions?: string;
  children: any;
}
const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const { children, routePermissions } = props;
  const checkPermission = () => {
    const permissions = getPermissions();
    console.log({permissions})
    let allPermissions = permissions
      .flatMap((item) => {
        return item.permissions;
      })
      .reduce((prev, curr) => {
        const updatedObject = { ...prev, ...curr };
        return updatedObject;
      }, {});

    for (const key in allPermissions) {
      if (routePermissions === key && allPermissions[key] === "N/A") {
        return false;
      } else if (routePermissions === key && allPermissions[key] !== "N/A") {
        return true;
      }
    }
  };
  if (routePermissions === "All") {
    return children;
  }
  return checkPermission() ? children : <AccessDenied />;
};
export default ProtectedRoute;
