export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_SEARCH_USER_SUCCESS = "FETCH_SEARCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const FETCH_USER_IN_PROGRESS = "FETCH_USER_IN_PROGRESS";

export interface IReturnType {
  type: string;
  payload: any;
}
