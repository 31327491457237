export const UPDATE_END_USER_SUCCESS = "UPDATE_END_USER_SUCCESS";
export const FETCH_END_USER_SUCCESS = "FETCH_END_USER_SUCCESS";
export const FETCH_SEARCH_END_USER_SUCCESS = "FETCH_SEARCH_END_USER_SUCCESS";
export const FETCH_END_USER_FAILURE = "FETCH_END_USER_FAILURE";
export const FETCH_END_USER_IN_PROGRESS = "FETCH_END_USER_IN_PROGRESS";
export const FETCH_END_USER_ORDERS_SUCCESS = "FETCH_END_USER_ORDERS_SUCCESS";

export interface IReturnType {
  type: string;
  payload: any;
}
