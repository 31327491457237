import * as types from "../types/keywordMaskingType";



export interface I_Keyword_Masking_InitialState {
    getKeywordsLoader: boolean;
    keywords: string[];
    updateKeywordsLoader: boolean;
    getKeywordsError: string;
    updateKeywordsError: string;
}

const initialState = {
    getKeywordsLoader: false,
    keywords: [],
    updateKeywordsLoader: false,
    getKeywordsError: '',
    updateKeywordsError: '',
};

export const keywordMaskingReducer = (
  state: I_Keyword_Masking_InitialState = initialState,
  action: types.IReturnType
) => {
  switch (action.type) {
    case types.GET_KEYWORDS_FAILURE:
        return { ...state, getKeywordsError: action?.payload }
    case types.UPDATE_KEYWORDS_FAILURE:
        return { ...state, updateKeywordsError: action?.payload }
    case types.GET_KEYWORDS_LOADER:
        return { ...state, getKeywordsLoader: action?.payload }
    case types.UPDATE_KEYWORDS_LOADER:
        return { ...state, updateKeywordsLoader: action?.payload }
    case types.GET_KEYWORDS_SUCCESS:
        return { ...state, keywords: action?.payload }
    default:
      return state;
  }
};
