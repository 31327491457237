export const ADD_LOYALTY_CONFIG = "ADD_LOYALTY_CONFIG";
export const UPDATE_LOYALTY_CONFIG = "UPDATE_LOYALTY_CONFIG";
export const DELETE_LOYALTY_CONFIG = "DELETE_LOYALTY_CONFIG";
export const FETCH_LOYALTY_CONFIG_SUCCESS = "FETCH_LOYALTY_CONFIG_SUCCESS";
export const FETCH_LOYALTY_CONFIG_FAILURE = "FETCH_LOYALTY_CONFIG_FAILURE";
export const FETCH_LOYALTY_CONFIG_IN_PROGRESS =
  "FETCH_LOYALTY_CONFIG_IN_PROGRESS";

export interface IReturnType {
  type: string;
  payload: any;
}
