import * as types from "../types/userWalletTypes";

export interface I_WALLET {
  first_name: string;
  last_name: string;
  id: number;
  balance: number;
  user_verification_status: string;
  compliance_status: string;
  updated_at: string;
  created_at: string;
}

export interface IWalletInitialState {
  errMsg: string;
  wallets: Array<I_WALLET>;
  loading: boolean;
  totalRecords: number;
  offset: number;
  rowsCount: number;
  page: number;
  action_loading: boolean;
  action_success: boolean | null;
  action_err_msg: string;
  show_action_form: boolean;
  current_action: string;
}

const initialState = {
  errMsg: "",
  wallets: [],
  loading: false,
  totalRecords: 0,
  offset: 0,
  rowsCount: 10,
  page: 1,
  action_loading: false,
  action_success: null,
  action_err_msg: '',
  show_action_form: false,
  current_action: ''
};

export const userWalletReducer = (
  state: IWalletInitialState = initialState,
  action: types.IReturnType
) => {
  switch (action.type) {
    
    case types.FETCH_SUCCESS:
      return {
        ...state,
        wallets: action.payload.wallets,
        offset: action.payload.offset,
      };
    case types.FETCH_FAILURE:
      return { ...state, errMsg: action.payload };
    case types.FETCH_IN_PROGRESS:
      return { ...state, loading: action.payload };
    case types.ACTION_SUCCESS:
      return {
        ...state,
        action_success: action.payload
      };
    case types.ACTION_FAILURE:
      return { ...state, action_err_msg: action.payload };
    case types.ACTION_IN_PROGRESS:
      return { ...state, action_loading: action.payload };
    case types.SHOW_ACTION_FORM:
      return { ...state, show_action_form: action.payload };
    default:
      return state;
  }
};
