export const ADD_CAMPAIGNS_SUCCESS = "ADD_CAMPAIGNS_SUCCESS";
export const FETCH_CAMPAIGNS_SUCCESS = "FETCH_CAMPAIGNS_SUCCESS";
export const FETCH_CAMPAIGNS_FAILURE = "FETCH_CAMPAIGNS_FAILURE";
export const FETCH_CAMPAIGNS_IN_PROGRESS = "FETCH_CAMPAIGNS_IN_PROGRESS";
export const UPDATE_CAMPAIGNS_SUCCESS = "UPDATE_CAMPAIGNS_SUCCESS";

export interface IReturnType {
  type: string;
  payload: any;
}
