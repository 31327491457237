import moment from 'moment-timezone';
export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

export const dateTimeFormatter = (date: any) => {
  if (date) {
    const newDate = new Date(date);
    return `${newDate.getDate()} ${
      monthNames[newDate.getMonth()]
    } ${newDate.getFullYear()} . ${formatAMPM(newDate)}`;
  }
  return date;
};

export const getDateTimeInISOFormat = (date: any) => {
  return new Date(date).toISOString();
};
export const dateFormatter = (date: any) => {
  if (date) {
    const newDate = new Date(date);
    return `${newDate.getDate()} ${
      monthNames[newDate.getMonth()]
    } ${newDate.getFullYear()}`;
  }
  return date;
};

export const convertISO = (date: any) => {
  return new Date(date).toISOString();
};

export const capitalize = (str: string) => {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};

export const capitalizeEmailName = (str: string) => {
  return str
    .replaceAll('_', ' ')
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
};

export const lowerCaseEmailName = (str: string) => {
  return str.replaceAll(' ', '_').toLowerCase();
};

export const getContestBody = (contest) => {
  const body = {
    contestPriority: contest.contestPriority,
    endTime: contest.endTime,
    entryFee: contest.entryFee,
    frequency: contest.frequency,
    highestPrize: contest.highestPrize,
    marketingText: contest.marketingText,
    multiEntryPerInstance: contest.multiEntryPerInstance,
    category: contest.category,
    respawn: contest.respawn,
    startTime: contest.startTime,
    totalNotionalEntryFee: contest.totalNotionalEntryFee,
    totalPrizePool: contest.totalPrizePool,
    totalSpots: contest.totalSpots,
    name: contest.name,
    rake: Number(contest.rake),
  };

  return body;
};

export const getWinningsArray = (winnings) => {
  const formattedWinnings = winnings.map((item) => ({
    rankFrom: item.rankFrom,
    rankTo: item.rankTo,
    prizeAmount: item.prizeAmount,
    id: item.id,
  }));

  return formattedWinnings;
};

//CheckChildrenPermissions
export const checkEditPermission = (
  permissions,
  permissionType,
  routeName,
  childrenName
) => {
  const modulePerm = permissions?.find((module) => {
    return module.label === routeName;
  });
  const childrenRoutePerm = modulePerm?.permissions?.find((item) => {
    return item[childrenName];
  });
  if (permissionType === 'view') {
    return childrenRoutePerm?.[childrenName] !== 'N/A';
  } else return childrenRoutePerm?.[childrenName] === 'Edit';
};

//routePermissions
export const checkRoutePermission = (permissions, routeName) => {
  const modulePerm = permissions.find((module) => {
    return module?.label === routeName;
  });

  if (modulePerm?.permissions) {
    let naCount = 0;
    for (const permission of modulePerm?.permissions) {
      for (const key in permission) {
        if (permission[key] === 'N/A') {
          naCount++;
        }
      }
    }
    return modulePerm?.permissions.length === naCount ? true : false;
  }
  return;
};

//SearchFilter
export const searchFormFilter = (data) => {
  if (data.Date) {
    data.from_date = data.Date.fromDate;
    data.to_date = data.Date.toDate;
    delete data.Date;
  }
  const searchData = Object.keys(data)
    .map((key) => `${key}=${data[key]}`)
    .join('&');
  if (Object.keys(data).length === 0) {
    return ``;
  } else {
    return `${searchData}`;
  }
};

//UpdatedByUpdatedAt
export const updatedTime = () => {
  const time = Date.now();
  let today = new Date(time);
  today = moment.utc(today).format('LLL');
  return today;
};

export const addLimitOffsetToUrl = (
  limit: number,
  offset: number,
  url: string
) => {
  if (limit) {
    url += `?limit=${limit}`;
  }
  if (offset) {
    url += `&offset=${offset}`;
  }
  return url;
};

/**
 * handles the formatting of date
 * @param date is the date to be formatted
 * @returns date formatted in YYYY/MM/DD to be saved in BE
 */
export const convertDateToSQLDate = (date: Date) => {
  const dateString = moment(date).format('YYYY-MM-DD');
  return dateString;
};

export const removeSpecialChars = (str: string) => {
  return str.replace(/[^a-zA-Z ]/g, "");
}

export const deepCopy = (data: any) => {
  return JSON.parse(JSON.stringify(data) || '{}')
}

export const mergeStyles = (...styles: any) => {
  return styles.reduce((mergedStyle: any, style: any) => mergedStyle = {...mergedStyle, ...style}, {})
} 

export const toCamel = (str: string) => {
  if(!str)
    return ''
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}