import * as types from "../types/consumeProvideTypes";

export interface ICategories {
  id: number;
  status: string;
  category: string;
  sub_category: string;
}
export interface IConsumeProvideConfig {
  id: number;
  label: string;
  value: string;
}

export interface ICategoryInitialState {
  errMsg: string;
  consume_Provide: Object;
  categories: Array<ICategories>;
  config: Array<IConsumeProvideConfig>;
  loading: boolean;
  totalRecords: number;
  rowsCount: number;
  page: number;
}

const initialState = {
  errMsg: "",
  consume_Provide: {},
  categories: [],
  config: [],
  loading: false,
  totalRecords: 0,
  rowsCount: 10,
  page: 1,
};

export const consumeProvideReducer = (
  state: ICategoryInitialState = initialState,
  action: types.IReturnType
) => {
  switch (action.type) {
    case types.ADD_CATEGORY:
      return {
        ...state,
        category: [...state.categories, action.payload],
      };
    case types.UPDATE_CATEGORY:
      return {
        ...state,
        category: state.categories.map((category) => {
          if (category.id === action.payload.id) return action.payload;
          else return category;
        }),
      };
    case types.FETCH_CONSUME_PROVIDE_SUCCESS:
      return {
        ...state,
        categories: action.payload.categories,
        config: action.payload.config,
      };
    case types.FETCH_CONSUME_PROVIDE_FAILURE:
      return { ...state, errMsg: action.payload };
    case types.FETCH_CONSUME_PROVIDE_IN_PROGRESS:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
