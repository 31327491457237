import * as types from "../types/requestTypes";

export interface I_TRANSACTION_REQUEST {
  consumer_first_name: string;
  consumer_last_name: string;
  created_at: string;
  id: number;
  order_code: string;
  order_id: number;
  orderStatus: string;
  provider_first_name: string;
  provider_last_name: string;
  scheduleDate: string;
  scheduleTime: string;
  status_tag: string;
  updated_at: string;
}

export interface ITransactionRequestsInitialState {
  errMsg: string;
  requests: Array<I_TRANSACTION_REQUEST>;
  loading: boolean;
  totalRecords: number;
  offset: number;
  rowsCount: number;
  page: number;
}

const initialState = {
  errMsg: "",
  requests: [],
  loading: false,
  totalRecords: 0,
  offset: 0,
  rowsCount: 10,
  page: 1,
};

export const transactionRequestReducer = (
  state: ITransactionRequestsInitialState = initialState,
  action: types.IReturnType
) => {
  switch (action.type) {
    case types.ADD_REQUEST_SUCCESS:
      return {
        ...state,
        requests: [...state.requests, action.payload],
      };
    case types.FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        requests: action.payload.requests,
        offset: action.payload.offset,
        totalRecords: action.payload.totalRecords,
        page: action.payload.page,
      };
    case types.FETCH_REQUEST_FAILURE:
      return { ...state, errMsg: action.payload };
    case types.FETCH_REQUEST_IN_PROGRESS:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
