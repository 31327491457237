export const ADD_SERVICE_SUCCESS = "ADD_SERVICE_SUCCESS";
export const FETCH_SERVICE_SUCCESS = "FETCH_SERVICE_SUCCESS";
export const FETCH_SERVICE_FAILURE = "FETCH_SERVICE_FAILURE";
export const FETCH_SERVICE_IN_PROGRESS = "FETCH_SERVICE_IN_PROGRESS";
export const FETCH_APP_USERS_SUCCESS = "FETCH_APP_USERS_SUCCESS";

export interface IReturnType {
  type: string;
  payload: any;
}
