import * as types from "../types/advertisementType";

export interface I_ADVERTISEMENT {
  advertisement_id: number;
  name: string;
  cta_url_link: string;
  cta: any;
  title: string;
  from_date: string;
  image_url: string;
  distance: number;
  to_date: string;
  updated_by: string;
  updated_at_utc: string;
}
export interface I_BUSINESS_USERS {
  id: number;
  user_id: string;
  email: string;
  phone_no: string;
}

export interface I_ADVERTISEMENT_InitialState {
  errMsg: string;
  advertisements: Array<I_ADVERTISEMENT>;
  businessUsers: Array<I_BUSINESS_USERS>;
  loading: boolean;
  totalRecords: number;
  rowsCount: number;
  page: number;
}

const initialState = {
  errMsg: "",
  advertisements: [],
  businessUsers: [],
  loading: false,
  totalRecords: 0,
  rowsCount: 10,
  page: 1,
};

export const advertisementReducer = (
  state: I_ADVERTISEMENT_InitialState = initialState,
  action: types.IReturnType
) => {
  switch (action.type) {
    case types.ADD_ADVERTISEMENT:
      return {
        ...state,
        advertisements: [action.payload, ...state.advertisements],
      };
    case types.UPDATE_ADVERTISEMENT:
      return {
        ...state,
        advertisements: state.advertisements.map((item) => {
          if (item.advertisement_id === action.payload.advertisement_id) {
            return action.payload;
          }
          return item;
        }),
      };
    case types.FETCH_ADVERTISEMENT_SUCCESS:
      return {
        ...state,
        advertisements: action.payload.advertisements,
        totalRecords: action.payload.totalRecords,
        page: action.payload.page,
      };
    case types.FETCH_BUSINESS_USERS_SUCCESS:
      return {
        ...state,
        businessUsers: action.payload.businessUsers,
      };
    case types.FETCH_ADVERTISEMENT_FAILURE:
      return { ...state, errMsg: action.payload };
    case types.FETCH_ADVERTISEMENT_IN_PROGRESS:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
