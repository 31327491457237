export const FETCH_FEEDBACK_FORM_SUCCESS = 'FETCH_FEEDBACK_FORM_SUCCESS';
export const FETCH_FEEDBACK_FORM_FAILURE = 'FETCH_FEEDBACK_FORM_FAILURE';
export const FETCH_FEEDBACK_FORM_LOADER = 'FETCH_FEEDBACK_FORM_LOADER';
export const UPDATE_FEEDBACK_FORM_SUCCESS = 'UPDATE_FEEDBACK_FORM_SUCCESS';
export const UPDATE_FEEDBACK_FORM_FAILURE = 'UPDATE_FEEDBACK_FORM_FAILURE';
export const UPDATE_FEEDBACK_FORM_LOADER = 'UPDATE_FEEDBACK_FORM_LOADER';

export interface IReturnType {
  type: string;
  payload: any;
}