import * as types from "../types/myNeighbourhoodTypes";

// export interface I_Feedback {
//   id: number;
//   question: string;
//   answer: string;
// }

export interface INeighbourhood_InitialState {
  getOptionsError: string;
  neighbourhoodOptions: any;
  loading: boolean;
  updateLoading: boolean;
  updateOptionsError: string;
}

const initialState = {
  getOptionsError: "",
  neighbourhoodOptions: {},
  loading: false,
  updateLoading: false,
  updateOptionsError: '',
};

export const myNeighbourhoodReducer = (
  state: INeighbourhood_InitialState = initialState,
  action: types.IReturnType
) => {
  switch (action.type) {
    case types.FETCH_NEIGHBOURHOOD_OPTIONS_SUCCESS:
      return { ...state, neighbourhoodOptions: action?.payload?.utilities_list };
    case types.FETCH_NEIGHBOURHOOD_OPTIONS_FAILURE:
      return { ...state, getFeedbackFormError: action?.payload };
    case types.FETCH_NEIGHBOURHOOD_OPTIONS_LOADER:
      return { ...state, loading: action?.payload };
    case types.UPDATE_NEIGHBOURHOOD_OPTIONS_FAILURE:
        return { ...state, updateOptionsError: action?.payload };
    case types.UPDATE_NEIGHBOURHOOD_OPTIONS_LOADER:
        return { ...state, updateLoading: action?.payload };
    default:
      return state;
  }
};
