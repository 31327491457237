export const ADD_PAYMENT_CONFIG_SUCCESS = "ADD_PAYMENT_CONFIG_SUCCESS";
export const UPDATE_PAYMENT_CONFIG_SUCCESS = "UPDATE_PAYMENT_CONFIG_SUCCESS";
export const DELETE_PAYMENT_CONFIG_SUCCESS = "DELETE_PAYMENT_CONFIG_SUCCESS";
export const FETCH_PAYMENT_CONFIG_SUCCESS = "FETCH_PAYMENT_CONFIG_SUCCESS";
export const FETCH_PAYMENT_CONFIG_FAILURE = "FETCH_PAYMENT_CONFIG_FAILURE";
export const FETCH_PAYMENT_CONFIG_IN_PROGRESS =
  "FETCH_PAYMENT_PAYMENT_CONFIG_IN_PROGRESS";

export interface IReturnType {
  type: string;
  payload: any;
}
