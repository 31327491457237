export const ADD_POSTS_SUCCESS = "ADD_POSTS_SUCCESS";
export const FETCH_POSTS_SUCCESS = "FETCH_POSTS_SUCCESS";
export const FETCH_POSTS_FAILURE = "FETCH_POSTS_FAILURE";
export const FETCH_POSTS_IN_PROGRESS = "FETCH_POSTS_IN_PROGRESS";
export const UPDATE_POSTS_SUCCESS = "UPDATE_POSTS_SUCCESS";

export interface IReturnType {
  type: string;
  payload: any;
}
