import * as types from "../types/feedReportTypes";

export interface I_FEED_REPORT {
  id: number;
  userId: number;
  entityId: string;
  image: string;
  caption: string;
  currentStatus: string;
  adminComment: string;
}
export interface I_REPORT_POSTS {
  id: number;
  image_urls: string;
  description: string;
}

export interface IFeedReportInitialState {
  errMsg: string;
  reports: Array<I_FEED_REPORT>;
  posts: Array<I_REPORT_POSTS>;
  loading: boolean;
  totalRecords: number;
  rowsCount: number;
  page: number;
  nextToken: string;
  prevToken: string;
  tokens: Object;
}

const initialState = {
  errMsg: "",
  reports: [],
  posts: [],
  loading: false,
  totalRecords: 0,
  rowsCount: 10,
  page: 1,
  nextToken: "",
  prevToken: "",
  tokens: {},
};

export const feedReportReducer = (
  state: IFeedReportInitialState = initialState,
  action: types.IReturnType
) => {
  switch (action.type) {
    case types.APPROVE_REPORT_SUCCESS:
      return {
        ...state,
        reports: state.reports.map((report) => {
          if (report.entityId === action.payload.entityId)
            return {
              ...report,
              currentStatus: "APPROVED",
            };
          else return report;
        }),
      };
    case types.REJECT_REPORT_SUCCESS:
      return {
        ...state,
        reports: state.reports.map((report) => {
          if (report.entityId === action.payload.entityId)
            return {
              ...report,
              currentStatus: "REJECTED",
            };
          else return report;
        }),
      };
    case types.FETCH_REPORT_SUCCESS:
      return {
        ...state,
        reports: action.payload.reports,
        nextToken: action.payload.nextToken,
        prevToken: action.payload.prevToken,
        page: action.payload.page,
        tokens: action.payload.tokens,
      };
    case types.FETCH_SEARCH_REPORT_SUCCESS:
      return {
        ...state,
        reports: action.payload.reports,
      };
    case types.FETCH_POSTS_SUCCESS:
      return {
        ...state,
        posts: action.payload.posts,
      };
    case types.FETCH_REPORT_FAILURE:
      return { ...state, errMsg: action.payload };
    case types.FETCH_REPORT_IN_PROGRESS:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
