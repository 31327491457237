export const ADD_CATEGORY = "ADD_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const FETCH_CONSUME_PROVIDE_SUCCESS = "FETCH_CONSUME_PROVIDE_SUCCESS";
export const FETCH_CONSUME_PROVIDE_FAILURE = "FETCH_CONSUME_PROVIDE_FAILURE";
export const FETCH_CONSUME_PROVIDE_IN_PROGRESS =
  "FETCH_CONSUME_PROVIDE_IN_PROGRESS";

export interface IReturnType {
  type: string;
  payload: any;
}
