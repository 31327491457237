export const ADD_SEND_NOTIFICATION = "ADD_SEND_NOTIFICATION";
export const UPDATE_SEND_NOTIFICATION = "UPDATE_SEND_NOTIFICATION";
export const DELETE_SEND_NOTIFICATION = "DELETE_SEND_NOTIFICATION";
export const FETCH_SEND_NOTIFICATION_SUCCESS =
  "FETCH_SEND_NOTIFICATION_SUCCESS";
export const FETCH_SEND_NOTIFICATION_FAILURE =
  "FETCH_SEND_NOTIFICATION_FAILURE";
export const FETCH_SEND_NOTIFICATION_IN_PROGRESS =
  "FETCH_SEND_NOTIFICATION_IN_PROGRESS";

export interface IReturnType {
  type: string;
  payload: any;
}
