import axios from "axios";

import config from "../config";

axios.defaults.baseURL = config.baseURL;

axios.defaults.headers.common["Content-Type"] =
  "application/json; charset=utf-8;";

export const setAxiosAuthToken = (token) => {
  const localStorageToken = localStorage.getItem("idToken");
  axios.defaults.headers.common["Authorization"] = token || localStorageToken;
};

export const setAxiosAuthTokenInLocalStorage = (token) => {
  localStorage.setItem("idToken", token);
};

export const setPermissionInLocalStorage = (permissions) => {
  localStorage.setItem("permissions", permissions);
};
export const getPermissions = () => {
  return JSON.parse(localStorage.getItem("permissions")) || [];
};

export const setUserEmailInLocalStorage = (email) => {
  localStorage.setItem("email", email);
};
export const getEmail = () => {
  return localStorage.getItem("email") || "";
};
export const setUserDetail = (userDetail) => {
  const userDetails = userDetail.toString();
  localStorage.setItem("userDetail", userDetails);
};
export const setUserID = (userID) => {
  localStorage.setItem("userID", userID);
};

export const getUserID = () => {
  return localStorage.getItem("userID") || "";
};
export const getUserDetail = () => {
  return JSON.parse(localStorage.getItem("userDetail")) || {};
};
export const getAuthToken = () => {
  return localStorage.idToken || null;
};

export const setUserIDInLocalStorage = (userID) => {
  localStorage.setItem("userID", userID);
};

setAxiosAuthToken("");
export default axios;
