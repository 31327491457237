import * as types from "../types/endUserTypes";

export interface IEndUsers {
  id: number;
  endUserName: string;
  email: string;
  phone_no: string;
  user_id: string;
}
export interface IEndUserBalanceAndOrders {
  id: number;
  wallet_balance: number;
  open_orders: number;
}

export interface IEndUsersInitialState {
  errMsg: string;
  endUsers: Array<IEndUsers>;
  endUserBalanceAndOrders: IEndUserBalanceAndOrders;
  loading: boolean;
  totalRecords: number;
  rowsCount: number;
  page: number;
}

const initialState = {
  errMsg: "",
  endUsers: [],
  endUserBalanceAndOrders: {
    id: 0,
    wallet_balance: 0,
    open_orders: 0,
  },
  loading: false,
  totalRecords: 0,
  rowsCount: 10,
  page: 1,
};

export const endUserReducer = (
  state: IEndUsersInitialState = initialState,
  action: types.IReturnType
) => {
  switch (action.type) {
    case types.UPDATE_END_USER_SUCCESS:
      return {
        ...state,
        endUsers: state.endUsers.map((endUser) => {
          if (endUser.user_id === action.payload.user_id) return action.payload;
          else return endUser;
        }),
      };
    case types.FETCH_END_USER_SUCCESS:
      return {
        ...state,
        endUsers: action.payload.endUsers,
        totalRecords: action.payload.totalRecords,
        page: action.payload.page,
      };
    case types.FETCH_SEARCH_END_USER_SUCCESS:
      return {
        ...state,
        endUsers: action.payload.endUsers,
        totalRecords: action.payload.totalRecords,
        page: action.payload.page,
      };
    case types.FETCH_END_USER_ORDERS_SUCCESS:
      const { endUserBalanceAndOrders } = action.payload;
      return {
        ...state,
        endUserBalanceAndOrders: { ...endUserBalanceAndOrders },
      };
    case types.FETCH_END_USER_FAILURE:
      return { ...state, errMsg: action.payload };
    case types.FETCH_END_USER_IN_PROGRESS:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
