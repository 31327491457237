export const ADD_FAQS_SUCCESS = "ADD_FAQS_SUCCESS";
export const UPDATE_FAQS_SUCCESS = "UPDATE_FAQS_SUCCESS";
export const DELETE_FAQS_SUCCESS = "DELETE_FAQS_SUCCESS";
export const FETCH_FAQS_SUCCESS = "FETCH_FAQS_SUCCESS";
export const FETCH_FAQS_FAILURE = "FETCH_FAQS_FAILURE";
export const FETCH_FAQS_IN_PROGRESS = "FETCH_FAQS_IN_PROGRESS";

export interface IReturnType {
  type: string;
  payload: any;
}
