import React, { useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { Helmet } from "react-helmet";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";

import Routes from "./routes/Routes";
import maTheme from "./theme";
import { store } from "./redux-store";
import { getEnv } from "./configs/env";
import { getFirebaseUser } from "./configs/firebaseConfig";
import { setAxiosAuthToken } from "./configs/axiosInterceptor";

function App() {
  useEffect(() => {
    const ENV = getEnv();
    console.log("environment", ENV);
  });

  const tokens = async () => {
    let token = "";
    const user = await getFirebaseUser();
    if (user) {
      token = await user.getIdToken(true);
    }
    setAxiosAuthToken(token);
  };
  useEffect(() => {
    tokens();
  }, []);

  return (
    <React.Fragment>
      <Helmet titleTemplate="%s | Hikinjo" defaultTitle="Hikinjo" />
      <Provider store={store}>
        <StylesProvider injectFirst>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiThemeProvider theme={maTheme[3]}>
              <ThemeProvider theme={maTheme[3]}>
                <Routes />
              </ThemeProvider>
            </MuiThemeProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </Provider>
    </React.Fragment>
  );
}

export default App;
