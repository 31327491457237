import * as types from "../types/myuserTypes";

export interface IPermissions {
  id: number;
  email: string;
  password: string;
  myUserPermission: any;
}

export interface IMyUserInitialState {
  errMsg: string;
  myUserPermission: Array<IPermissions>;
  token: string;
  loading: boolean;
}

const initialState = {
  errMsg: "",
  myUserPermission: [],
  token: "",
  loading: false,
};

export const myUserReducer = (
  state: IMyUserInitialState = initialState,
  action: types.IReturnType
) => {
  switch (action.type) {
    case types.FETCH_PERMISSIONS_SUCCESS:
      return {
        ...state,
        myUserPermission: action.payload,
        token: action.payload,
      };
    default:
      return state;
  }
};
