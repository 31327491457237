import * as types from "../types/notificationTypes";

export interface ITemplates {
  id: number;
  notificationType: string;
  title: string;
  body: string;
  subject: string;
  data: Array<Object>;
}

export interface INotificationActions {
  id: number;
  action: string;
}

export interface INotificationInitialState {
  errMsg: string;
  templates: Array<ITemplates>;
  notificationsActions: Array<INotificationActions>;
  loading: boolean;
  totalRecords: number;
  rowsCount: number;
  page: number;
}

const initialState = {
  errMsg: "",
  templates: [],
  notificationsActions: [],
  loading: false,
  totalRecords: 0,
  rowsCount: 10,
  page: 1,
};

export const notificationReducer = (
  state: INotificationInitialState = initialState,
  action: types.IReturnType
) => {
  switch (action.type) {
    case types.ADD_NOTIFICATION:
      return {
        ...state,
        templates: [...state.templates, action.payload],
      };
    case types.UPDATE_NOTIFICATION:
      return {
        ...state,
        templates: state.templates.map((template) => {
          if (template.id === action.payload.id) {
            return action.payload;
          }
          return template;
        }),
      };
    case types.FETCH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        templates: action.payload.templates,
      };
    case types.FETCH_NOTIFICATION_ACTIONS_SUCCESS:
      return {
        ...state,
        notificationsActions: action.payload.notificationsActions,
      };
    case types.FETCH_NOTIFICATION_FAILURE:
      return { ...state, errMsg: action.payload };
    case types.FETCH_NOTIFICATION_IN_PROGRESS:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
