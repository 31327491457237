import * as types from "../types/onboardingTypes";

export interface ISlides {
  id: number;
  name: string;
  description: string;
  image_url: string;
  bg_color_code: string;
  status: string;
}
export interface IONBOARDING_CONFIG {
  id: number;
  label: string;
  value: string;
}

export interface IDOCUMENTATION {
  id: number;
  documentationType: string;
  status: string;
}
export interface ITermsAndConditions {
  id: number;
  text: string;
}
export interface IPrivacy_Policy {
  id: number;
  text: string;
}
export interface IOnboardingInitialState {
  errMsg: string;
  slides: Array<ISlides>;
  config: Array<IONBOARDING_CONFIG>;
  addressVerficationDocument: Array<IDOCUMENTATION>;
  termsAndConditions: string;
  privacyPolicy: string;
  loading: boolean;
  totalRecords: number;
  rowsCount: number;
  page: number;
  onboarding: Object;
}

const initialState = {
  errMsg: "",
  onboarding: {},
  slides: [],
  config: [],
  addressVerficationDocument: [],
  termsAndConditions: "",
  privacyPolicy: "",
  loading: false,
  totalRecords: 0,
  rowsCount: 10,
  page: 1,
};

export const onboardingReducer = (
  state: IOnboardingInitialState = initialState,
  action: types.IReturnType
) => {
  switch (action.type) {
    case types.ADD_SLIDE:
      return { ...state, slides: [...state.slides, action.payload] };
    case types.UPDATE_SLIDE:
      return {
        ...state,
        slides: state.slides.map((slide) => {
          if (slide.id === action.payload.id) return action.payload;
          else return slide;
        }),
      };
    case types.FETCH_ONBOARDING_SUCCESS:
      return {
        ...state,
        onboarding: action.payload.onboarding,
        config: action.payload.config,
        slides: action.payload.slides,
        addressVerficationDocument: action.payload.addressVerficationDocument,
        termsAndConditions: action.payload.termsAndConditions,
        privacyPolicy: action.payload.privacyPolicy,
      };
    case types.FETCH_ONBOARDING_FAILURE:
      return { ...state, errMsg: action.payload };
    case types.FETCH_ONBOARDING_IN_PROGRESS:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
