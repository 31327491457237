import * as types from "../types/snackbarTypes";

export interface ISnackbarInitialState {
  message: string;
  severity: string;
  showSnackbar: boolean;
}

const initialState = {
  message: "",
  severity: "error",
  showSnackbar: false,
};

export const snackbarReducer = (
  state: ISnackbarInitialState = initialState,
  action: types.IReturnType
) => {
  switch (action.type) {
    case types.SET_SNACKBAR:
      return {
        ...state,
        message: action.payload.message,
        severity: action.payload.severity,
        showSnackbar: action.payload.showSnackbar,
      };

    case types.RESET_SNACKBAR:
      return { ...state, showSnackbar: false };

    default:
      return state;
  }
};
