export const ADD_SLIDE = "ADD_SLIDE";
export const UPDATE_SLIDE = "UPDATE_SLIDE";
export const DELETE_SLIDE = "DELETE_SLIDE";
export const FETCH_ONBOARDING_SUCCESS = "FETCH_ONBOARDING_SUCCESS";
export const FETCH_ONBOARDING_FAILURE = "FETCH_ONBOARDING_FAILURE";
export const FETCH_ONBOARDING_IN_PROGRESS = "FETCH_ONBOARDING_IN_PROGRESS";

export interface IReturnType {
  type: string;
  payload: any;
}
