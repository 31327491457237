export const ADD_ADVERTISEMENT = "ADD_ADVERTISEMENT";
export const UPDATE_ADVERTISEMENT = "UPDATE_ADVERTISEMENT";
export const DELETE_ADVERTISEMENT = "DELETE_ADVERTISEMENT";
export const FETCH_ADVERTISEMENT_SUCCESS = "FETCH_ADVERTISEMENT_SUCCESS";
export const FETCH_BUSINESS_USERS_SUCCESS = "FETCH_BUSINESS_USERS_SUCCESS";
export const FETCH_ADVERTISEMENT_FAILURE = "FETCH_ADVERTISEMENT_FAILURE";
export const FETCH_ADVERTISEMENT_IN_PROGRESS =
  "FETCH_ADVERTISEMENT_IN_PROGRESS";

export interface IReturnType {
  type: string;
  payload: any;
}
