import * as types from "../types/sendNotificationTypes";

export interface ISendNotification {
  id: number;
  notificationType: string;
  title: string;
  body: string;
  subject: string;
  sendBy: string;
  timeStamp: string;
}

export interface ISendNotificationInitialState {
  errMsg: string;
  sendNotifications: Array<ISendNotification>;
  loading: boolean;
  totalRecords: number;
  rowsCount: number;
  page: number;
}

const initialState = {
  errMsg: "",
  sendNotifications: [],
  loading: false,
  totalRecords: 0,
  rowsCount: 10,
  page: 1,
};

export const sendNotificationReducer = (
  state: ISendNotificationInitialState = initialState,
  action: types.IReturnType
) => {
  switch (action.type) {
    case types.ADD_SEND_NOTIFICATION:
      return {
        ...state,
        sendNotifications: [...state.sendNotifications, action.payload],
      };
    case types.UPDATE_SEND_NOTIFICATION:
      return {
        ...state,
        templates: state.sendNotifications.map((template) => {
          if (template.id === action.payload.id) {
            return action.payload;
          }
          return template;
        }),
      };
    case types.FETCH_SEND_NOTIFICATION_SUCCESS:
      return {
        ...state,
        sendNotifications: action.payload.sendNotifications,
        totalRecords: action.payload.totalRecords,
        page: action.payload.page,
      };
    case types.FETCH_SEND_NOTIFICATION_FAILURE:
      return { ...state, errMsg: action.payload };
    case types.FETCH_SEND_NOTIFICATION_IN_PROGRESS:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
