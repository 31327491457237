import * as types from "../types/postCodeTypes";

export interface IPOST_CODE {
  id: number;
  country_name: string;
  country_code: string;
  notallowed_postcode: string;
}

export interface IPOSTCODEInitialState {
  errMsg: string;
  postCodes: Array<IPOST_CODE>;
  loading: boolean;
  totalRecords: number;
  rowsCount: number;
  page: number;
}

const initialState = {
  errMsg: "",
  postCodes: [],
  loading: false,
  totalRecords: 0,
  rowsCount: 10,
  page: 1,
};

export const postCodeReducer = (
  state: IPOSTCODEInitialState = initialState,
  action: types.IReturnType
) => {
  switch (action.type) {
    case types.ADD_POST_CODE:
      return {
        ...state,
        postCodes: [...state.postCodes, action.payload],
      };
    case types.UPDATE_POST_CODE:
      return {
        ...state,
        postCodes: state.postCodes.map((postCode) => {
          if (postCode.id === action.payload.id) return action.payload;
          else return postCode;
        }),
      };
    case types.FETCH_POST_CODE_SUCCESS:
      return {
        ...state,
        postCodes: action.payload.postCodes,
        totalRecords: action.payload.totalRecords,
        page: action.payload.page,
      };
    case types.FETCH_POST_CODE_FAILURE:
      return { ...state, errMsg: action.payload };
    case types.FETCH_POST_CODE_IN_PROGRESS:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
