import * as types from "../types/feedbackFormTypes";

export interface I_Feedback {
  id: number;
  question: string;
  answer: string;
}
export interface I_Feedback_Form_Emoji {
  url: string;
  tags: string[];
  title: string;
  status: string;
}
export interface I_Feedback_Form {
      id: number;
      status: string;
      active_date: string;
      description: string;
      kudos_points: number;
      updated_date: string;
      inactive_date: string;
      kudos_enabled: boolean;
      feedback_question: string;
      feedback_answer_list: Array<I_Feedback_Form_Emoji>,
      feedback_note_question: string;
      feedback_tags_question: string;
      feedback_note_placeHolder: string;
}

export interface IFeedback_Form_InitialState {
  feedbackForm: Array<I_Feedback_Form>;
  getFeedbackFormLoader: boolean;
  getFeedbackFormError: string;
  updateFeedbackFormLoader: boolean;
  updateFeedbackFormError: string;
}

const initialState = {
  feedbackForm: [],
  getFeedbackFormLoader: false,
  getFeedbackFormError: "",
  updateFeedbackFormLoader: false,
  updateFeedbackFormError: "",
};

export const feedbackFormReducer = (
  state: IFeedback_Form_InitialState = initialState,
  action: types.IReturnType
) => {
  switch (action.type) {
    case types.FETCH_FEEDBACK_FORM_SUCCESS:
      return { ...state, feedbackForm: action?.payload };
    case types.FETCH_FEEDBACK_FORM_FAILURE:
      return { ...state, getFeedbackFormError: action?.payload };
    case types.FETCH_FEEDBACK_FORM_LOADER:
      return { ...state, getFeedbackFormLoader: action?.payload };
    case types.UPDATE_FEEDBACK_FORM_LOADER:
        return { ...state, updateFeedbackFormLoader: action?.payload };
    case types.UPDATE_FEEDBACK_FORM_FAILURE:
        return { ...state, updateFeedbackFormError: action?.payload };
    case types.UPDATE_FEEDBACK_FORM_LOADER:
        return { ...state, feedbackForm: action?.payload }
    default:
      return state;
  }
};
