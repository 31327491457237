export const ADD_RULES_SUCCESS = "ADD_RULES_SUCCESS";
export const UPDATE_RULES_SUCCESS = "UPDATE_RULES_SUCCESS";
export const DELETE_RULES_SUCCESS = "DELETE_RULES_SUCCESS";
export const FETCH_RULES_SUCCESS = "FETCH_RULES_SUCCESS";
export const FETCH_RULES_FAILURE = "FETCH_RULES_FAILURE";
export const FETCH_RULES_IN_PROGRESS = "FETCH_RULES_IN_PROGRESS";

export interface IReturnType {
  type: string;
  payload: any;
}
