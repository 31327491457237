import React, { useState, useEffect } from "react";

import { fireAuth } from "../configs/firebaseConfig";
import Loader from "../components/Loader";

const withAuthProtection = (path) => (WrappedComponent) => {
  return (props) => {
    const [auth, setAuth] = useState("");
    useEffect(() => {
      fireAuth.onAuthStateChanged(async (user) => {
        if (!user) {
          // eslint-disable-next-line no-restricted-globals
          props.history.push("/sign-in");
        } else {
          await user.getIdToken().then((idToken) => {
            setAuth(idToken);
            localStorage.setItem("idToken", idToken);
          });
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.roles]);
    if (auth) return <WrappedComponent {...props} />;
    return <Loader />;
  };
};

export default withAuthProtection;
