export const GET_KEYWORDS_SUCCESS = 'GET_KEYWORDS_SUCCESS';
export const GET_KEYWORDS_FAILURE = 'GET_KEYWORDS_FAILURE';
export const GET_KEYWORDS_LOADER = 'GET_KEYWORDS_LOADER';
export const UPDATE_KEYWORDS_SUCCESS = 'UPDATE_KEYWORDS_SUCCESS';
export const UPDATE_KEYWORDS_FAILURE = 'UPDATE_KEYWORDS_FAILURE';
export const UPDATE_KEYWORDS_LOADER = 'UPDATE_KEYWORDS_LOADER';

export interface IReturnType {
  type: string;
  payload: any;
}