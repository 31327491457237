import * as types from "../types/postsTypes";

export interface I_TRANSACTION_POSTS {
  description: string;
  starting_date: string;
  ending_date: string;
  feed_id: string;
}

export interface ITransactionPostsInitialState {
  errMsg: string;
  posts: Array<I_TRANSACTION_POSTS>;
  loading: boolean;
  totalRecords: number;
  offset: number;
  rowsCount: number;
  page: number;
}

const initialState = {
  errMsg: "",
  posts: [],
  loading: false,
  totalRecords: 0,
  offset: 0,
  rowsCount: 10,
  page: 1,
};

export const transactionPostsReducer = (
  state: ITransactionPostsInitialState = initialState,
  action: types.IReturnType
) => {
  switch (action.type) {
    case types.ADD_POSTS_SUCCESS:
      return {
        ...state,
        posts: [action.payload, ...state.posts],
      };
    case types.UPDATE_POSTS_SUCCESS:
      return {
        ...state,
        posts: state.posts.map((posts) => {
          if (posts.feed_id === action.payload.feed_id) return action.payload;
          else return posts;
        }),
      };

    case types.FETCH_POSTS_SUCCESS:
      return {
        ...state,
        posts: action.payload.posts,
        offset: action.payload.offset,
        totalRecords: action.payload.totalRecords,
        page: action.payload.page,
      };
    case types.FETCH_POSTS_FAILURE:
      return { ...state, errMsg: action.payload };
    case types.FETCH_POSTS_IN_PROGRESS:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
