export const ADD_INTEREST_SUCCESS = "ADD_INTEREST_SUCCESS";
export const UPDATE_INTEREST_SUCCESS = "UPDATE_INTEREST_SUCCESS";
export const DELETE_INTEREST_SUCCESS = "DELETE_INTEREST_SUCCESS";
export const FETCH_INTEREST_SUCCESS = "FETCH_INTEREST_SUCCESS";
export const FETCH_INTEREST_FAILURE = "FETCH_INTEREST_FAILURE";
export const FETCH_INTEREST_IN_PROGRESS = "FETCH_INTEREST_IN_PROGRESS";

export interface IReturnType {
  type: string;
  payload: any;
}
