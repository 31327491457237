import * as types from "../types/feedbackTypes";

export interface I_Feedback {
  id: number;
  question: string;
  answer: string;
}

export interface IFeedback_InitialState {
  errMsg: string;
  review_feedBack: Array<I_Feedback>;
  loading: boolean;
  totalRecords: number;
  rowsCount: number;
  page: number;
}

const initialState = {
  errMsg: "",
  review_feedBack: [],
  loading: false,
  totalRecords: 0,
  rowsCount: 10,
  page: 1,
};

export const feedbackReducer = (
  state: IFeedback_InitialState = initialState,
  action: types.IReturnType
) => {
  switch (action.type) {
    case types.FETCH_FEEDBACK_SUCCESS:
      const { review_feedBack, totalRecords, page } = action.payload;
      return {
        ...state,
        review_feedBack: review_feedBack,
        totalRecords: totalRecords,
        page: page,
      };
    case types.FETCH_FEEDBACK_FAILURE:
      return { ...state, errMsg: action.payload };
    case types.FETCH_FEEDBACK_IN_PROGRESS:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
