import * as types from "../types/recommendedTopicsTypes";

export interface I_RECOMMENDED_TOPICS {
  id: number;
  title: string;
  content: string;
  image: string;
}

export interface IRecommendedTopicsInitialState {
  errMsg: string;
  recommendedTopics: Array<I_RECOMMENDED_TOPICS>;
  loading: boolean;
  totalRecords: number;
  rowsCount: number;
  page: number;
}

const initialState = {
  errMsg: "",
  recommendedTopics: [],
  loading: false,
  totalRecords: 0,
  rowsCount: 10,
  page: 1,
};

export const recommendedTopicsReducer = (
  state: IRecommendedTopicsInitialState = initialState,
  action: types.IReturnType
) => {
  switch (action.type) {
    case types.ADD_RECOMMENDED_TOPICS_SUCCESS:
      return {
        ...state,
        recommendedTopics: [...state.recommendedTopics, action.payload],
      };
    case types.UPDATE_RECOMMENDED_TOPICS_SUCCESS:
      return {
        ...state,
        recommendedTopics: state.recommendedTopics.map((topic) => {
          return topic.id === action.payload.id ? action.payload : topic;
        }),
      };
    case types.FETCH_RECOMMENDED_TOPICS_SUCCESS:
      return {
        ...state,
        recommendedTopics: action.payload.recommendedTopics,
      };
    case types.FETCH_RECOMMENDED_TOPICS_FAILURE:
      return { ...state, errMsg: action.payload };
    case types.FETCH_RECOMMENDED_TOPICS_IN_PROGRESS:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
