export const FETCH_NEIGHBOURHOOD_OPTIONS_SUCCESS = "FETCH_NEIGHBOURHOOD_OPTIONS_SUCCESS";
export const FETCH_NEIGHBOURHOOD_OPTIONS_FAILURE = "FETCH_NEIGHBOURHOOD_OPTIONS_FAILURE";
export const FETCH_NEIGHBOURHOOD_OPTIONS_LOADER = "FETCH_NEIGHBOURHOOD_OPTIONS_LOADER";
export const UPDATE_NEIGHBOURHOOD_OPTIONS_FAILURE = "UPDATE_NEIGHBOURHOOD_OPTIONS_FAILURE";
export const UPDATE_NEIGHBOURHOOD_OPTIONS_LOADER = "UPDATE_NEIGHBOURHOOD_OPTIONS_LOADER";

export interface IReturnType {
  type: string;
  payload: any;
}
