import * as types from "../types/policiesTypes";

export interface I_POLICIES {
  id: number;
  label: string;
  title: string;
  url: string;
}

export interface IPoliciesInitialState {
  errMsg: string;
  loading: boolean;
  page: number;
  policy: Array<I_POLICIES>;
  rowsCount: number;
  totalRecords: number;
}

const initialState = {
  errMsg: "",
  policy: [],
  loading: false,
  totalRecords: 0,
  rowsCount: 10,
  page: 1,
};

export const policiesReducer = (
  state: IPoliciesInitialState = initialState,
  action: types.IReturnType
) => {
  switch (action.type) {
    case types.ADD_POLICIES_SUCCESS:
      return { ...state, policy: [...state.policy, action.payload] };
    case types.UPDATE_POLICIES_SUCCESS:
      return {
        ...state,
        policy: state.policy.map((policy) => {
          return policy.id === action.payload.id ? action.payload : policy;
        }),
      };
    case types.FETCH_POLICIES_SUCCESS:
      return {
        ...state,
        policy: action.payload.policy,
      };
    case types.FETCH_POLICIES_FAILURE:
      return { ...state, errMsg: action.payload };
    case types.FETCH_POLICIES_IN_PROGRESS:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
