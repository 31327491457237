export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_FAILURE = "FETCH_FAILURE";
export const FETCH_IN_PROGRESS = "FETCH_IN_PROGRESS";
export const ACTION_IN_PROGRESS = "ACTION_IN_PROGRESS";
export const ACTION_SUCCESS = "ACTION_SUCCESS";
export const ACTION_FAILURE = "ACTION_FAILURE";
export const SHOW_ACTION_FORM = 'SHOW_ACTION_FORM';

export interface IReturnType {
  type: string;
  payload: any;
}
