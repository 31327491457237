export const APPROVE_REPORT_SUCCESS = "APPROVE_REPORT_SUCCESS";
export const REJECT_REPORT_SUCCESS = "REJECT_REPORT_SUCCESS";
export const FETCH_REPORT_SUCCESS = "FETCH_REPORT_SUCCESS";
export const FETCH_POSTS_SUCCESS = "FETCH_POSTS_SUCCESS";
export const FETCH_SERVICES_SUCCESS = "FETCH_SERVICES_SUCCESS";
export const FETCH_REPORT_FAILURE = "FETCH_REPORT_FAILURE";
export const FETCH_REPORT_IN_PROGRESS = "FETCH_REPORT_IN_PROGRESS";
export const FETCH_SEARCH_REPORT_SUCCESS = "FETCH_SEARCH_REPORT_SUCCESS";

export interface IReturnType {
  type: string;
  payload: any;
}
