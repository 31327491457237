export const ADD_POLICIES_SUCCESS = "ADD_POLICIES_SUCCESS";
export const UPDATE_POLICIES_SUCCESS = "UPDATE_POLICIES_SUCCESS";
export const DELETE_POLICIES_SUCCESS = "DELETE_POLICIES_SUCCESS";
export const FETCH_POLICIES_SUCCESS = "FETCH_POLICIES_SUCCESS";
export const FETCH_POLICIES_FAILURE = "FETCH_POLICIES_FAILURE";
export const FETCH_POLICIES_IN_PROGRESS = "FETCH_POLICIES_IN_PROGRESS";

export interface IReturnType {
  type: string;
  payload: any;
}
